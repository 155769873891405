.present-section {
  padding: 90px 0;
  overflow: hidden;
  background-color: #e7e5e6;

  // @media (max-width: 991.98px) {
  //   padding: 60px 0;
  // }

  @media (max-width: 667px) {    
    padding: 30px 0;
  }

  &__title {
    
    color: #fff;
    font-size: 21px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 20px;
    font-family: "Open Sans";
    background-color: #dc724e;
    display: inline-block;
    padding: 15px 23px;



    @media (max-width: 667px) {
      font-size: 17px;      
      margin-bottom: 10px;  
      padding: 14px 18px;   

    }
    
  }


  &__cont {
   
    display: flex;
    position: relative;

    @media (max-width: 667px) {
      display: block;
    }
 
  }

 

  &__text-large {
    color: #000000;
    font-family: "Open Sans";
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 40px;
    line-height: 1;

    @media (max-width: 667px) {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 18px;
    }

    
  }

  &__link {}

  &__img {}

  &__imagebox {
    flex: 0 0 47%;
    
    position: relative;

    @media (max-width: 667px) {
      flex: 0 0 300px;
      margin-left: auto;
      margin-right: auto;
      order: 1;
      margin-top: 20px;
    }
    img{
      max-width: 100%;
    }
  }

  &__info {
    // max-width: 410px;
    position: relative;

    padding-right: 40px;

    @media (max-width: 767.98px) {}

    p {

      color: #000;
      font-family: "Open Sans";
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 25px;

      &:last-child{
        margin-bottom: 0;
      }

      @media (max-width: 667px) {

        font-size: 14px;
        margin-bottom: 18px;
      }

    }

    a {
      color: #47abde;
      text-decoration: none;
      border-bottom: 1px solid #47abde;
      transition: all .3s;

      &:hover{
        border-color: transparent;
      }
    }
  }
}

#surprize{
    @media (max-width: 667px) {  
      padding: 30px 0 0;
    }
}

.bonus_section {

  img {
    max-width: 100%;
  }


  @media (max-width: 667px) {
    padding-top: 30px;
    padding-bottom: 30px;

    .jurbonus {
      text-align: left;

      span {
        font-size: 17px;
        font-weight: 300;
        padding: 14px 22px;
        height: auto !important;
        line-height: 1 !important;
      }
    }

    .title {
      margin-bottom: 26px;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.1;
      text-align: left;

      br {
        display: none;
      }
    }

    .leftblock {
      float: none;
      width: 100%;
    }

    .rightblock {

      width: 100%;
      padding-left: 0;
      float: none;
    }

  }

}

